import React from "react";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import data from "../categories.json";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useCookies } from "react-cookie";
const Admin = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);

  useEffect(() => {
    if (!cookies.uToken) {
      window.location.href = "/login";
    }
  }, []);

  const [isloading, setIsLoading] = useState(false);
  const [addToggle, setAddToggle] = useState("");

  const [udata, setUdata] = useState(data);
  const hasValueWithLengthOne = Object.values(udata).some((value) => value !== "budget" && value.length >= 1);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const config = {
      headers: { "content-type": "application/json", Authorization: cookies.uToken },
    };
    Axios.post("https://admin.flippcadeau.nl/add-remove-categories", JSON.stringify(udata), config)
      .then((response) => {
        console.log(response);
        setUdata(response.data);
        setIsLoading(false);
        toast.success("Success!");
        Axios.post("https://admin.flippcadeau.nl/get-all-categories", JSON.stringify(udata), config)
          .then((response) => {
            console.log(response);
            setUdata(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            toast.error("Something went Wrong. Please retry!");
          });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error("Something went Wrong. Please retry!");
      });
    console.log(udata);
  };

  const [catName, setNewCat] = useState({
    name: "",
    icon: "",
  });

  const [catAge, setAgeNewCat] = useState({
    ageFrom: "",
    ageTo: "",
  });

  const handleAddCat = (e) => {
    e.preventDefault();

    // Validate that catName has both name and icon properties
    if ((!catName.name && addToggle !== "age") || (!catName.icon && addToggle !== "age")) {
      console.error("Both name and icon must be provided for the new category.");
      toast.error("Both name and icon must be provided for the new category.");
      return;
    }

    // if ((!catName.ageFrom && addToggle == "age") || (!catName.ageto && addToggle == "age")) {
    //   console.error("Both ageFrom ageTo and icon must be provided for the new category.");
    //   toast.error("Both name and icon must be provided for the new category.");
    //   return;
    // }

    if (addToggle == "age") {
      setUdata((prevUdata) => {
        const updatedUdata = prevUdata.map((item) => (item.type === addToggle ? { ...item, data_set: [...item.data_set, catAge] } : item));
        toast.success("Added Age Sub-Catgeory");
        return updatedUdata;
      });
    }

    if (addToggle !== "age") {
      setUdata((prevUdata) => {
        const updatedUdata = prevUdata.map((item) => (item.type === addToggle ? { ...item, data_set: [...item.data_set, catName] } : item));
        toast.success("Added Sub-Catgeory");
        return updatedUdata;
      });
    }

    // Reset the catName state after adding
    setAgeNewCat({
      ageFrom: "",
      ageTo: "",
    });
    setNewCat({
      name: "",
      icon: "",
    });
  };

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", Authorization: cookies.uToken },
    };
    Axios.post("https://admin.flippcadeau.nl/get-all-categories", JSON.stringify(udata), config)
      .then((response) => {
        console.log(response);
        setUdata(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error("Something went Wrong. Please retry!");
      });
  }, []);

  return (
    <>
      <Toaster />

      <div className="w-100 d-flex justify-content-end p-3">
        <a className="btn btn-light border border-1 shadow-sm  border-dark me-2 rounded fw-bold py-2" href="/add_gift">
          Add Gift
        </a>

        {cookies.uToken !== undefined && cookies.uToken.length !== 0 && (
          <button
            class="btn fw-bold py-2  btn-dark d-flex align-items-center justify-content-center me-2"
            aria-current="page"
            href="#"
            onClick={() =>
              toast((t) => (
                <span>
                  <b>Log Out? </b>
                  <a
                    className="btn bg-info text-light  mx-2"
                    onClick={() => {
                      removeCookie("uToken");
                      toast.dismiss(t.id);
                      window.location.href = "/login";
                    }}
                  >
                    <i className="fa fa-check"></i>
                  </a>
                  <a className="btn bg-dark text-light" onClick={() => toast.dismiss(t.id)}>
                    <i className="fa fa-times"></i>
                  </a>
                </span>
              ))
            }
          >
            Logout <i className="fa fa-sign-out ps-2" />
          </button>
        )}
      </div>

      <div className="mainGen d-flex align-items-center p-lg-0 p-3">
        {console.log(udata)}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.3 }}
          variants={{ visible: { opacity: 1, scale: 1 }, hidden: { opacity: 0, scale: 0.7 } }}
          className="container rounded bg-white shadow border border-secondary border-1 my-auto p-lg-5 py-4"
        >
          <div className="text-center mb-5">
            <h1 className="fs-2 fw-bold">Manage Category</h1>
            <h2 className="text-wrap w-75 mx-auto fs-3">Add or Remove categories</h2>
          </div>

          <form className="form row" onSubmit={handleSubmit}>
            <div className="col-lg-6">
              <div className="row mb-lg-5 mb-3">
                <div className="col-12 d-flex justify-content-between">
                  <div class="dropdown w-75">
                    <button
                      class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <i className="fs-3 fa fa-user me-2"></i>
                      Gender
                    </button>

                    <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                      {udata
                        .filter((e) => e.type == "gender")
                        .map((ini) => {
                          return ini.data_set.map((jini, k) => {
                            return (
                              <li>
                                <a class="dropdown-item " href="#">
                                  <label for={`gender${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                    <div class={`form-check d-flex justify-content-between px-0 w-100 `}>
                                      <span className=" d-flex align-items-center w-75">
                                        <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                        <label class="form-check-label" for={`gender${k}`}>
                                          {jini.name}
                                        </label>
                                      </span>
                                      <input
                                        className="form-check-input input-del"
                                        type="checkbox"
                                        value={jini.name}
                                        id={`gender${k}`}
                                        onClick={(e) => {
                                          const newInterests = ini.data_set.filter((item) => item.name !== jini.name);

                                          // Update the state with the new interests
                                          setUdata((prevUdata) => {
                                            const updatedUdata = prevUdata.map((item) => (item.type === "gender" ? { ...item, data_set: newInterests } : item));
                                            return updatedUdata;
                                          });
                                        }}
                                      />
                                    </div>
                                  </label>
                                </a>
                              </li>
                            );
                          });
                        })}
                    </ul>
                  </div>

                  <button className="btn btn-light p-3 btn-lg mb-3 border-1 border border-dark rounded fs-6" type="button" role="button" onClick={() => setAddToggle("gender")}>
                    <i className="fa fa-plus-circle  me-2 "></i>
                    Add
                  </button>
                </div>

                <div className="col-12 d-flex justify-content-between">
                  <div class="dropdown w-75">
                    <button
                      class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <i className="fs-3 fa fa-birthday-cake me-2"></i> Occasion
                    </button>

                    <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                      {udata
                        .filter((e) => e.type == "occasion")
                        .map((ini) => {
                          return ini.data_set.map((jini, k) => {
                            return (
                              <li>
                                <a class="dropdown-item" href="#">
                                  <label for={`occasion${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                    <div class={`form-check d-flex justify-content-between px-0 w-100`}>
                                      <span className=" d-flex align-items-center w-75">
                                        <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                        <label class="form-check-label" for={`occasion${k}`}>
                                          {jini.name}
                                        </label>
                                      </span>
                                      <input
                                        className="form-check-input input-del"
                                        type="checkbox"
                                        value={jini.name}
                                        id={`occasion${k}`}
                                        onClick={(e) => {
                                          const newInterests = ini.data_set.filter((item) => item.name !== jini.name);

                                          // Update the state with the new interests
                                          setUdata((prevUdata) => {
                                            const updatedUdata = prevUdata.map((item) => (item.type === "occasion" ? { ...item, data_set: newInterests } : item));
                                            return updatedUdata;
                                          });
                                        }}
                                      />
                                    </div>
                                  </label>
                                </a>
                              </li>
                            );
                          });
                        })}
                    </ul>
                  </div>

                  <button className="btn btn-light p-3 btn-lg mb-3 border-1 border border-dark rounded fs-6" type="button" role="button" onClick={() => setAddToggle("occasion")}>
                    <i className="fa fa-plus-circle  me-2 "></i>
                    Add
                  </button>
                </div>

                <div className="col-12 mb-3 d-flex justify-content-between">
                  <div class="dropdown w-75">
                    <button
                      class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <i className="fs-3 fa fa-book me-2"></i> Interests
                    </button>

                    <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                      {udata
                        .filter((e) => e.type == "interest")
                        .map((ini) => {
                          return ini.data_set.map((jini, k) => {
                            return (
                              <li>
                                <a class="dropdown-item" href="#">
                                  <label for={`interest${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                    <div class={`form-check d-flex justify-content-between px-0 w-100 `}>
                                      <span className=" d-flex align-items-center w-75">
                                        <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                        <label class="form-check-label" for={`interest${k}`}>
                                          {jini.name}
                                        </label>
                                      </span>
                                      <input
                                        className="form-check-input input-del"
                                        type="checkbox"
                                        value={jini.name}
                                        id={`interest${k}`}
                                        onClick={(e) => {
                                          const newInterests = ini.data_set.filter((item) => item.name !== jini.name);

                                          // Update the state with the new interests
                                          setUdata((prevUdata) => {
                                            const updatedUdata = prevUdata.map((item) => (item.type === "interest" ? { ...item, data_set: newInterests } : item));
                                            return updatedUdata;
                                          });
                                        }}
                                      />
                                    </div>
                                  </label>
                                </a>
                              </li>
                            );
                          });
                        })}
                    </ul>
                  </div>

                  <button className="btn btn-light p-3 btn-lg mb-3 border-1 border border-dark rounded fs-6" type="button" role="button" onClick={() => setAddToggle("interest")}>
                    <i className="fa fa-plus-circle  me-2 "></i>
                    Add
                  </button>
                </div>

                <div className="col-12 d-flex justify-content-between">
                  <div class="dropdown w-75">
                    <button
                      class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <i className="fs-3 fa fa-gift me-2"></i> Type of Gift
                    </button>

                    <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                      {udata
                        .filter((e) => e.type == "typofgift")
                        .map((ini) => {
                          return ini.data_set.map((jini, k) => {
                            return (
                              <li>
                                <a class="dropdown-item" href="#">
                                  <label for={`typofgift${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                    <div class={`form-check d-flex justify-content-between px-0 w-100`}>
                                      <span className=" d-flex align-items-center w-75">
                                        <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                        <label class="form-check-label" for={`typofgift${k}`}>
                                          {jini.name}
                                        </label>
                                      </span>
                                      <input
                                        className="form-check-input input-del"
                                        type="checkbox"
                                        value={jini.name}
                                        id={`typofgift${k}`}
                                        onClick={(e) => {
                                          const newInterests = ini.data_set.filter((item) => item.name !== jini.name);

                                          // Update the state with the new interests
                                          setUdata((prevUdata) => {
                                            const updatedUdata = prevUdata.map((item) => (item.type === "typofgift" ? { ...item, data_set: newInterests } : item));
                                            return updatedUdata;
                                          });
                                        }}
                                      />
                                    </div>
                                  </label>
                                </a>
                              </li>
                            );
                          });
                        })}
                    </ul>
                  </div>

                  <button className="btn btn-light p-3 btn-lg mb-3 border-1 border border-dark rounded fs-6" type="button" role="button" onClick={() => setAddToggle("typofgift")}>
                    <i className="fa fa-plus-circle  me-2 "></i>
                    Add
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row">
                <div className="col-12 d-flex justify-content-between">
                  <div class="dropdown w-75">
                    <button
                      class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <i className="fs-3 fa fa-map-marker me-2"></i> Area
                    </button>

                    <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                      {udata
                        .filter((e) => e.type == "area")
                        .map((ini) => {
                          return ini.data_set.map((jini, k) => {
                            return (
                              <li>
                                <a class="dropdown-item" href="#">
                                  <label for={`area${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                    <div class={`form-check d-flex justify-content-between px-0 w-100 `}>
                                      <span className=" d-flex align-items-center w-75">
                                        <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                        <label class="form-check-label" for={`area${k}`}>
                                          {jini.name}
                                        </label>
                                      </span>
                                      <input
                                        className="form-check-input input-del"
                                        type="checkbox"
                                        value={jini.name}
                                        id={`area${k}`}
                                        onClick={(e) => {
                                          const newInterests = ini.data_set.filter((item) => item.name !== jini.name);

                                          // Update the state with the new interests
                                          setUdata((prevUdata) => {
                                            const updatedUdata = prevUdata.map((item) => (item.type === "area" ? { ...item, data_set: newInterests } : item));
                                            return updatedUdata;
                                          });
                                        }}
                                      />
                                    </div>
                                  </label>
                                </a>
                              </li>
                            );
                          });
                        })}
                    </ul>
                  </div>
                  <button className="btn btn-light p-3 btn-lg mb-3 border-1 border border-dark rounded fs-6" type="button" role="button" onClick={() => setAddToggle("area")}>
                    <i className="fa fa-plus-circle  me-2 "></i>
                    Add
                  </button>
                </div>

                <div className="col-12 d-flex justify-content-between ">
                  <div class="dropdown w-75">
                    <button
                      class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <i className="fs-3 fa fa-calendar-times-o me-2"></i> Age
                    </button>

                    <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                      {udata
                        .filter((e) => e.type == "age")
                        .map((ini) => {
                          return ini.data_set.map((jini, k) => {
                            return (
                              <li>
                                <a class="dropdown-item" href="#">
                                  <label for={`age${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                    <div class={`form-check d-flex justify-content-between px-0 w-100 `}>
                                      <span className=" d-flex align-items-center w-75">
                                        <i className={"fa fa-calendar-times-o fs-3 me-2 w-25"}></i>
                                        <label class="form-check-label" for={`age${k}`}>
                                          {jini.ageFrom} - {jini.ageTo}
                                        </label>
                                      </span>
                                      <input
                                        className="form-check-input input-del"
                                        type="checkbox"
                                        value={jini.name}
                                        id={`age${k}`}
                                        onClick={(e) => {
                                          const newInterests = ini.data_set.filter((item) => item.ageFrom !== jini.ageFrom);

                                          // Update the state with the new interests
                                          setUdata((prevUdata) => {
                                            const updatedUdata = prevUdata.map((item) => (item.type === "age" ? { ...item, data_set: newInterests } : item));
                                            return updatedUdata;
                                          });
                                        }}
                                      />
                                    </div>
                                  </label>
                                </a>
                              </li>
                            );
                          });
                        })}
                    </ul>
                  </div>
                  <button className="btn btn-light p-3 btn-lg mb-3 border-1 border border-dark rounded fs-6" type="button" role="button" onClick={() => setAddToggle("age")}>
                    <i className="fa fa-plus-circle  me-2 "></i>
                    Add
                  </button>
                </div>

                <div className="col-12 d-flex justify-content-between">
                  <div class="dropdown w-75">
                    <button
                      class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <i className="fs-3 fa fa-heart me-2"></i>
                      Relation
                    </button>

                    <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                      {udata
                        .filter((e) => e.type == "relation")
                        .map((ini) => {
                          return ini.data_set.map((jini, k) => {
                            return (
                              <li>
                                <a class="dropdown-item" href="#">
                                  <label for={`relation${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                    <div class={`form-check d-flex justify-content-between px-0 w-100 `}>
                                      <span className=" d-flex align-items-center w-75">
                                        <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                        <label class="form-check-label" for={`relation${k}`}>
                                          {jini.name}
                                        </label>
                                      </span>
                                      <input
                                        className="form-check-input input-del"
                                        type="checkbox"
                                        value={jini.name}
                                        id={`relation${k}`}
                                        onClick={(e) => {
                                          const newInterests = ini.data_set.filter((item) => item.name !== jini.name);

                                          // Update the state with the new interests
                                          setUdata((prevUdata) => {
                                            const updatedUdata = prevUdata.map((item) => (item.type === "relation" ? { ...item, data_set: newInterests } : item));
                                            return updatedUdata;
                                          });
                                        }}
                                      />
                                    </div>
                                  </label>
                                </a>
                              </li>
                            );
                          });
                        })}
                    </ul>
                  </div>
                  <button className="btn btn-light p-3 btn-lg mb-3 border-1 border border-dark rounded fs-6" type="button" role="button" onClick={() => setAddToggle("relation")}>
                    <i className="fa fa-plus-circle  me-2 "></i>
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="w-100 text-end">
              <>
                {!isloading ? (
                  <button type="submit" class="btn btn-light p-3 d-flex align-items-center ms-auto fs-5 btn-lg border-dark mt-5">
                    <i className="fa fa-save fs-3 me-3"></i> Save
                  </button>
                ) : (
                  <button type="button" class="btn btn-light p-3 d-flex align-items-center ms-auto fs-5 btn-lg border-dark mt-5" disabled>
                    <i class="fa fa-circle-o-notch fa-spin me-2"></i> Loading
                  </button>
                )}
              </>
            </div>
          </form>
        </motion.div>
      </div>

      {addToggle !== "" && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ background: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", height: "100vh", width: "100vw", zIndex: "99", position: "fixed", top: "0", left: "0" }}
        >
          <div className="p-3 rounded shadow  bg-light" style={{ width: "18rem" }}>
            <form onSubmit={handleAddCat}>
              <div className="w-100 text-end">
                <button
                  class="btn btn-dark rounded-circle  mb-2"
                  onClick={() => {
                    setAddToggle("");
                  }}
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>

              {addToggle == "age" && (
                <div class="mb-3">
                  <label for="age1" class="form-label text-capitalize">
                    {addToggle} From
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="age1"
                    value={catAge.ageFrom}
                    onChange={(e) => {
                      setAgeNewCat({ ...catAge, ageFrom: e.target.value });
                    }}
                  />

                  <label for="age2" class="form-label text-capitalize">
                    {addToggle} To
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="age2"
                    value={catAge.ageTo}
                    onChange={(e) => {
                      setAgeNewCat({ ...catAge, ageTo: e.target.value });
                    }}
                  />
                </div>
              )}
              {addToggle !== "age" && (
                <>
                  <div class="mb-3">
                    <label for="text1" class="form-label text-capitalize">
                      Sub-Category Name for {addToggle}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="text1"
                      value={catName.name}
                      onChange={(e) => {
                        setNewCat({ ...catName, name: e.target.value });
                      }}
                    />
                  </div>
                  <label for="text2" class="form-label">
                    Enter icon class (eg: fa fa-heart)
                  </label>
                  <div class="mb-3 d-flex align-items-center">
                    <div className="w-75">
                      <input
                        type="text"
                        class="form-control"
                        id="text2"
                        placeholder="fa fa-heart"
                        value={catName.icon}
                        onChange={(e) => {
                          setNewCat({ ...catName, icon: e.target.value });
                        }}
                      />
                    </div>
                    <i className={`${catName.icon !== "" ? catName.icon : "fa fa-heart"} ms-2 fs-3 `}></i>
                  </div>
                </>
              )}

              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Admin;
