import logo from "./logo.svg";
import "./App.css";
import Main from "./components/main";
import Admin from "./components/admin/admin";
import AddGift from "./components/admin/addGift";
import Login from "./components/login";
import "./components/master.css";

function App() {
  if (window.location.pathname == "/") {
    return <Main />;
  } else if (window.location.pathname == "/add_gift") {
    return <AddGift />;
  } else if (window.location.pathname == "/admin") {
    return <Admin />;
  } else if (window.location.pathname == "/login") {
    return <Login />;
  }
}

export default App;
