import React from "react";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import mdata from "../categories.json";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useCookies } from "react-cookie";
const AddGift = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);

  const [data, setData] = useState(mdata);

  useEffect(() => {
    if (!cookies.uToken) {
      window.location.href = "/login";
    }
  }, []);

  const [isloading, setIsLoading] = useState(false);
  const [serverResponse, setResponse] = useState("");
  const [csvForm, setCsvForm] = useState(false);
  const [csv, setCsv] = useState("");
  const [isCorrectFileType, setCorrectFileType] = useState(false);
  const [udata, setUdata] = useState({
    name: "",
    content: "",
    card_title: "",
    price: 0,
    product_url: "",
    product_image_url: "",
    gender: [],
    occassion: [],
    interests: [],
    typegift: [],
    area: [],
    age: [],
    relation: [],
    budget: 0,
  });
  const hasValueWithLengthOne = Object.values(udata).every((value) => {
    if (typeof value === "string") {
      return value.length >= 1;
    } else if (value === "budget" && typeof value !== "number") {
      return true; // Exclude "budget" if it's not a number
    } else {
      return true; // For other types, consider them valid
    }
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    setUdata({ ...udata, price: "€" + udata.price });

    console.log(udata);

    const config = {
      headers: { "content-type": "application/json", Authorization: cookies.uToken },
    };

    Axios.post("https://admin.flippcadeau.nl/add-gift", JSON.stringify(udata), config)
      .then((response) => {
        console.log(response);
        setUdata({ ...udata, price: 0, budget: 0 });
        setIsLoading(false);

        toast.success("Success");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error("Something went Wrong!");
      });
  };

  const handleCsvSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(csv);

    const config = {
      headers: { "content-type": "application/json", Authorization: cookies.uToken },
    };

    let dataCsv = {
      file: csv,
    };

    console.log(dataCsv);
    Axios.post("https://admin.flippcadeau.nl/add-gift-excel", dataCsv, config)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setResponse("Success");
        toast.success("Success");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setResponse("Something went Wrong!");
        toast.error("Something went Wrong!");
      });
  };

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", Authorization: cookies.uToken },
    };
    Axios.post("https://admin.flippcadeau.nl/get-all-categories", JSON.stringify(udata), config)
      .then((response) => {
        console.log(response);
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error("Something went Wrong. Please retry!");
      });
  }, []);

  return (
    <>
      {/* {console.log(hasValueWithLengthOne)} */}
      {console.log(udata.budget.length)}
      <Toaster />
      <div className="w-100 d-flex justify-content-end p-3">
        <a className="btn btn-light border border-1 shadow-sm  border-dark me-2 rounded fw-bold py-2" href="/admin">
          Manage Category
        </a>

        {cookies.uToken !== undefined && cookies.uToken.length !== 0 && (
          <button
            class="btn fw-bold py-2  btn-dark d-flex align-items-center justify-content-center me-2"
            aria-current="page"
            href="#"
            onClick={() =>
              toast((t) => (
                <span>
                  <b>Log Out? </b>
                  <a
                    className="btn bg-info text-light  mx-2"
                    onClick={() => {
                      removeCookie("uToken");
                      toast.dismiss(t.id);
                      window.location.href = "/login";
                    }}
                  >
                    <i className="fa fa-check"></i>
                  </a>
                  <a className="btn bg-dark text-light" onClick={() => toast.dismiss(t.id)}>
                    <i className="fa fa-times"></i>
                  </a>
                </span>
              ))
            }
          >
            Logout <i className="fa fa-sign-out ps-2" />
          </button>
        )}
      </div>
      <div className="d-flex align-items-center mt-5">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.3 }}
          variants={{ visible: { opacity: 1, scale: 1 }, hidden: { opacity: 0, scale: 0.7 } }}
          className="container rounded bg-white shadow border border-secondary border-1 my-auto py-4"
        >
          <div className="text-center mb-5 d-flex px-3">
            <h1 className="fs-2 fw-bold">Add Gift</h1>
            <button className="btn btn-lg btn-dark ms-auto" onClick={() => setCsvForm(true)}>
              Uplaod CSV <i className="fa fa-upload"></i>
            </button>
          </div>

          <form className="form p-3" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-12 mb-3 ">
                    <div className="row d-lg-flex align-items-center">
                      <div className="col-lg-3">
                        <label for="giftname" class="form-label">
                          Gift Name
                        </label>
                      </div>
                      <div className="col-lg-9">
                        <input type="text" class="form-control p-3" id="giftname" aria-describedby="giftname" value={udata.name} onChange={(e) => setUdata({ ...udata, name: e.target.value })} />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3 ">
                    <div className="row d-lg-flex align-items-center">
                      <div className="col-lg-3">
                        <label for="giftname" class="form-label">
                          Card Title
                        </label>
                      </div>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          class="form-control p-3"
                          id="giftcardtitle"
                          aria-describedby="giftcardtitle"
                          value={udata.card_title}
                          onChange={(e) => setUdata({ ...udata, card_title: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3 ">
                    <div className="row d-lg-flex align-items-center">
                      <div className="col-lg-3">
                        <label for="giftname" class="form-label">
                          Gift Description
                        </label>
                      </div>
                      <div className="col-lg-9">
                        <textarea
                          class="form-control"
                          id="giftdesc"
                          aria-describedby="giftdesc"
                          value={udata.content}
                          onChange={(e) => setUdata({ ...udata, content: e.target.value })}
                          rows={8}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3 ">
                    <div className="row d-lg-flex align-items-center">
                      <div className="col-lg-3">
                        <label for="giftname" class="form-label">
                          Gift Price
                        </label>
                      </div>
                      <div className="col-lg-9">
                        <input
                          type="number"
                          class="form-control p-3"
                          id="giftprice"
                          aria-describedby="giftprice"
                          value={udata.budget}
                          onChange={(e) => setUdata({ ...udata, budget: parseInt(e.target.value) })}
                        />
                        {console.log(udata)}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3 ">
                    <div className="row d-lg-flex align-items-center">
                      <div className="col-lg-3">
                        <label for="giftname" class="form-label">
                          Price Label
                        </label>
                      </div>
                      <div className="col-9">
                        <input type="number" class="form-control p-3" id="giftlabel" aria-describedby="giftlabel" value={udata.price} onChange={(e) => setUdata({ ...udata, price: e.target.value })} />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3 ">
                    <div className="row d-lg-flex align-items-center">
                      <div className="col-lg-3">
                        <label for="giftname" class="form-label">
                          URL to product
                        </label>
                      </div>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          class="form-control p-3"
                          id="gifturl"
                          aria-describedby="gifturl"
                          value={udata.product_url}
                          onChange={(e) => setUdata({ ...udata, product_url: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3 ">
                    <div className="row d-lg-flex align-items-center">
                      <div className="col-lg-3">
                        <label for="formFile" class="form-label">
                          Gift Image URL
                        </label>
                      </div>
                      <div className="col-lg-9">
                        <input
                          class="form-control p-3"
                          type="text"
                          id="formFile"
                          value={udata.product_image_url}
                          onChange={(e) => {
                            setUdata({ ...udata, product_image_url: e.target.value });
                          }}
                          // onChange={(e) => {
                          //   let reader = new FileReader();
                          //   reader.addEventListener("load", () => {
                          //     // Get the Base64-encoded string from the FileReader result
                          //     let base64String = reader.result;

                          //     setUdata({ ...udata, product_image_url: e.target.value });
                          //     // Do something with the Base64-encoded string, such as display it in an <img> tag
                          //   });

                          //   reader.readAsDataURL(e.target.files[0]);
                          // }}
                        />
                        {udata.product_image_url !== "" && <img src={udata.product_image_url} height="150" className="shadow rounded mt-2" />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Side categories */}
              <div className="col-6">
                <div className="row mb-lg-5 mb-3">
                  <div className="col-12 ">
                    <div class="dropdown">
                      <button
                        class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 "
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <i className="fs-3 fa fa-user me-2"></i>{" "}
                        {udata.gender.length !== 0
                          ? udata.gender.map((ini) => {
                              return ini + ",";
                            })
                          : "Gender"}
                      </button>

                      <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                        {data
                          .filter((e) => e.type == "gender")
                          .map((ini) => {
                            return ini.data_set.map((jini, k) => {
                              return (
                                <li>
                                  <a class="dropdown-item" href="#">
                                    <label for={`gender${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                      <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.gender.includes(jini.name) ? "text-danger" : ""}`}>
                                        <span className=" d-flex align-items-center w-75">
                                          <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                          <label class="form-check-label text-capitalize" for={`gender${k}`}>
                                            {jini.name}
                                          </label>
                                        </span>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value={jini.name}
                                          id={`gender${k}`}
                                          onClick={(e) => {
                                            const newInterests = [...udata.gender];
                                            const interestIndex = newInterests.indexOf(e.target.value);

                                            if (interestIndex === -1) {
                                              // Item doesn't exist in the array, so add it.
                                              newInterests.push(e.target.value);
                                            } else {
                                              // Item exists in the array, so remove it.
                                              newInterests.splice(interestIndex, 1);
                                            }

                                            setUdata({ ...udata, gender: newInterests });
                                          }}
                                          checked={udata.gender.includes(jini.name) ? true : false}
                                        />
                                      </div>
                                    </label>
                                  </a>
                                </li>
                              );
                            });
                          })}
                      </ul>
                    </div>
                  </div>

                  <div className="col-12 ">
                    <div class="dropdown">
                      <button
                        class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 "
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <i className="fs-3 fa fa-birthday-cake me-2"></i> Occasion
                      </button>

                      <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                        {data
                          .filter((e) => e.type == "occasion")
                          .map((ini) => {
                            return ini.data_set.map((jini, k) => {
                              return (
                                <li>
                                  <a class="dropdown-item" href="#">
                                    <label for={`occasion${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                      <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.occassion.includes(jini.name) ? "text-danger" : ""}`}>
                                        <span className=" d-flex align-items-center w-75">
                                          <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                          <label class="form-check-label" for={`occasion${k}`}>
                                            {jini.name}
                                          </label>
                                        </span>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value={jini.name}
                                          id={`occasion${k}`}
                                          onClick={(e) => {
                                            const newInterests = [...udata.occassion];
                                            const interestIndex = newInterests.indexOf(e.target.value);

                                            if (interestIndex === -1) {
                                              // Item doesn't exist in the array, so add it.
                                              newInterests.push(e.target.value);
                                            } else {
                                              // Item exists in the array, so remove it.
                                              newInterests.splice(interestIndex, 1);
                                            }

                                            setUdata({ ...udata, occassion: newInterests });
                                          }}
                                          checked={udata.occassion.includes(jini.name) ? true : false}
                                        />
                                      </div>
                                    </label>
                                  </a>
                                </li>
                              );
                            });
                          })}
                      </ul>
                    </div>
                    <div className="w-100 slim-scroll mb-4" style={{ maxHeight: "60px", overflowY: "scroll" }}>
                      {udata.occassion.map((ini) => {
                        return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                      })}
                    </div>
                  </div>

                  <div className="col-12  mb-3">
                    <div class="dropdown">
                      <button
                        class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 "
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <i className="fs-3 fa fa-book me-2"></i> Interests
                      </button>

                      <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                        {data
                          .filter((e) => e.type == "interest")
                          .map((ini) => {
                            return ini.data_set.map((jini, k) => {
                              return (
                                <li>
                                  <a class="dropdown-item" href="#">
                                    <label for={`interests${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                      <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.interests.includes(jini.name) ? "text-danger" : ""}`}>
                                        <span className=" d-flex align-items-center w-75">
                                          <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                          <label class="form-check-label" for={`interests${k}`}>
                                            {jini.name}
                                          </label>
                                        </span>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value={jini.name}
                                          id={`interests${k}`}
                                          onClick={(e) => {
                                            const newInterests = [...udata.interests];
                                            const interestIndex = newInterests.indexOf(e.target.value);

                                            if (interestIndex === -1) {
                                              // Item doesn't exist in the array, so add it.
                                              newInterests.push(e.target.value);
                                            } else {
                                              // Item exists in the array, so remove it.
                                              newInterests.splice(interestIndex, 1);
                                            }

                                            setUdata({ ...udata, interests: newInterests });
                                          }}
                                          checked={udata.interests.includes(jini.name) ? true : false}
                                        />
                                      </div>
                                    </label>
                                  </a>
                                </li>
                              );
                            });
                          })}
                      </ul>
                    </div>

                    <div className="w-100 slim-scroll" style={{ maxHeight: "60px", overflowY: "scroll" }}>
                      {udata.interests.map((ini) => {
                        return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                      })}
                    </div>
                  </div>

                  <div className="col-12 ">
                    <div class="dropdown">
                      <button
                        class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 "
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <i className="fs-3 fa fa-gift me-2"></i> Type of Gift
                      </button>

                      <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                        {data
                          .filter((e) => e.type == "typofgift")
                          .map((ini) => {
                            return ini.data_set.map((jini, k) => {
                              return (
                                <li>
                                  <a class="dropdown-item" href="#">
                                    <label for={`gift-type${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                      <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.typegift.includes(jini.name) ? "text-danger" : ""}`}>
                                        <span className=" d-flex align-items-center w-75">
                                          <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                          <label class="form-check-label" for={`gift-type${k}`}>
                                            {jini.name}
                                          </label>
                                        </span>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value={jini.name}
                                          id={`gift-type${k}`}
                                          onClick={(e) => {
                                            const newInterests = [...udata.typegift];
                                            const interestIndex = newInterests.indexOf(e.target.value);

                                            if (interestIndex === -1) {
                                              // Item doesn't exist in the array, so add it.
                                              newInterests.push(e.target.value);
                                            } else {
                                              // Item exists in the array, so remove it.
                                              newInterests.splice(interestIndex, 1);
                                            }

                                            setUdata({ ...udata, typegift: newInterests });
                                          }}
                                          checked={udata.typegift.includes(jini.name) ? true : false}
                                        />
                                      </div>
                                    </label>
                                  </a>
                                </li>
                              );
                            });
                          })}
                      </ul>
                    </div>
                    <div className="w-100 slim-scroll " style={{ maxHeight: "60px", overflowY: "scroll" }}>
                      {udata.typegift.map((ini) => {
                        return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                      })}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 ">
                    <div class="dropdown">
                      <button
                        class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 "
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <i className="fs-3 fa fa-map-marker me-2"></i> Area
                      </button>

                      <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                        {data
                          .filter((e) => e.type == "area")
                          .map((ini) => {
                            return ini.data_set.map((jini, k) => {
                              return (
                                <li>
                                  <a class="dropdown-item" href="#">
                                    <label for={`area${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                      <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.area.includes(jini.name) ? "text-danger" : ""}`}>
                                        <span className=" d-flex align-items-center w-75">
                                          <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                          <label class="form-check-label" for={`area${k}`}>
                                            {jini.name}
                                          </label>
                                        </span>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value={jini.name}
                                          id={`area${k}`}
                                          onClick={(e) => {
                                            const newInterests = [...udata.area];
                                            const interestIndex = newInterests.indexOf(e.target.value);

                                            if (interestIndex === -1) {
                                              // Item doesn't exist in the array, so add it.
                                              newInterests.push(e.target.value);
                                            } else {
                                              // Item exists in the array, so remove it.
                                              newInterests.splice(interestIndex, 1);
                                            }

                                            setUdata({ ...udata, area: newInterests });
                                          }}
                                          checked={udata.area.includes(jini.name) ? true : false}
                                        />
                                      </div>
                                    </label>
                                  </a>
                                </li>
                              );
                            });
                          })}
                      </ul>
                    </div>
                    <div className="w-100 slim-scroll mb-4" style={{ maxHeight: "60px", overflowY: "scroll" }}>
                      {udata.area.map((ini) => {
                        return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                      })}
                    </div>
                  </div>

                  <div className="col-12 ">
                    <div class="dropdown">
                      <button
                        class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 "
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <i className="fs-3 fa fa-calendar-times-o me-2"></i> Age
                      </button>

                      <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                        {data
                          .filter((e) => e.type == "age")
                          .map((ini) => {
                            return ini.data_set.map((jini, k) => {
                              return (
                                <li>
                                  <a class="dropdown-item" href="#">
                                    <label for={`age${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                      <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.area.includes(jini.name) ? "text-danger" : ""}`}>
                                        <span className=" d-flex align-items-center w-75">
                                          <i className={"fa fa-calendar-times-o fs-3 me-2 w-25"}></i>
                                          <label class="form-check-label text-capitalize" for={`age${k}`}>
                                            {jini.ageFrom} - {jini.ageTo}
                                          </label>
                                        </span>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value={`${jini.ageFrom}-${jini.ageTo}`}
                                          id={`age${k}`}
                                          onClick={(e) => {
                                            const newInterests = [...udata.age];
                                            const interestIndex = newInterests.indexOf(e.target.value);

                                            if (interestIndex === -1) {
                                              // Item doesn't exist in the array, so add it.
                                              newInterests.push(e.target.value);
                                            } else {
                                              // Item exists in the array, so remove it.
                                              newInterests.splice(interestIndex, 1);
                                            }

                                            setUdata({ ...udata, age: newInterests });
                                          }}
                                          checked={udata.age.includes(`${jini.ageFrom}-${jini.ageTo}`) ? true : false}
                                        />
                                      </div>
                                    </label>
                                  </a>
                                </li>
                              );
                            });
                          })}
                      </ul>
                    </div>
                    <div className="w-100 slim-scroll mb-4" style={{ maxHeight: "60px", overflowY: "scroll" }}>
                      {udata.age.map((ini) => {
                        return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                      })}
                    </div>
                  </div>

                  <div className="col-12 ">
                    <div class="dropdown">
                      <button
                        class="btn btn-light p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 "
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <i className="fs-3 fa fa-heart me-2"></i>
                        Relation
                      </button>

                      <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                        {data
                          .filter((e) => e.type == "relation")
                          .map((ini) => {
                            return ini.data_set.map((jini, k) => {
                              return (
                                <li>
                                  <a class="dropdown-item" href="#">
                                    <label for={`relation${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                      <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.relation.includes(jini.name) ? "text-danger" : ""}`}>
                                        <span className=" d-flex align-items-center w-75">
                                          <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                          <label class="form-check-label" for={`relation${k}`}>
                                            {jini.name}
                                          </label>
                                        </span>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value={jini.name}
                                          id={`relation${k}`}
                                          onClick={(e) => {
                                            const newInterests = [...udata.relation];
                                            const interestIndex = newInterests.indexOf(e.target.value);

                                            if (interestIndex === -1) {
                                              // Item doesn't exist in the array, so add it.
                                              newInterests.push(e.target.value);
                                            } else {
                                              // Item exists in the array, so remove it.
                                              newInterests.splice(interestIndex, 1);
                                            }

                                            setUdata({ ...udata, relation: newInterests });
                                          }}
                                          checked={udata.relation.includes(jini.name) ? true : false}
                                        />
                                      </div>
                                    </label>
                                  </a>
                                </li>
                              );
                            });
                          })}
                      </ul>
                    </div>
                    <div className="w-100 slim-scroll mb-4" style={{ maxHeight: "60px", overflowY: "scroll" }}>
                      {udata.relation.map((ini) => {
                        return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100 text-end">
              {!hasValueWithLengthOne ? (
                <>
                  <button type="submit" class="btn btn-light p-3 d-flex align-items-center ms-auto fs-5 btn-lg border-dark mt-5" disabled>
                    <i className="fa fa-save fs-3 me-3"></i> Save Gift
                  </button>
                </>
              ) : (
                <>
                  {!isloading ? (
                    <button type="submit" class="btn btn-light p-3 d-flex align-items-center ms-auto fs-5 btn-lg border-dark mt-5">
                      <i className="fa fa-save fs-3 me-3"></i> Save Gift
                    </button>
                  ) : (
                    <button type="button" class="btn btn-light p-3 d-flex align-items-center ms-auto fs-5 btn-lg border-dark mt-5" disabled>
                      <i class="fa fa-circle-o-notch fa-spin me-2"></i> Loading
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </motion.div>
      </div>

      {/* CSV FORM */}
      {csvForm && (
        <div
          className="d-flex align-items-center justify-content-center "
          style={{ background: "rgba(0,0,0,0.5)", position: "fixed", top: 0, left: 0, width: "100%", height: "100vh", zIndex: "99", backdropFilter: "blur(10px)" }}
        >
          <form className="form p-3 bg-light rounded shadow-sm" onSubmit={handleCsvSubmit}>
            <div className="text-info w-100">{serverResponse}</div>
            <div className="w-100 text-end">
              <button role="button" className="btn btn-dark rounded-circle ms-auto" onClick={() => setCsvForm(false)}>
                <i className="fa fa-times"></i>
              </button>
            </div>
            <div className="row">
              <div className="col-12 mb-3 ">
                <div className="row d-lg-flex align-items-center">
                  <div className="col-12">
                    <label for="formFile" class="form-label">
                      Add CSV file:
                    </label>
                    <input
                      class="form-control p-3"
                      type="file"
                      id="formFile"
                      onChange={(e) => {
                        const allowedFileTypes = ["csv", "xlsx"];
                        const selectedFile = e.target.files[0];

                        if (selectedFile) {
                          const fileType = selectedFile.name.split(".").pop().toLowerCase();

                          if (allowedFileTypes.includes(fileType)) {
                            let reader = new FileReader();

                            reader.addEventListener("load", () => {
                              // Extract the base64 string from the data URL
                              const base64String = reader.result.split(",")[1];

                              setCsv(base64String);
                              setCorrectFileType(true);
                              // Do something with the Base64-encoded string, such as display it in an <img> tag
                            });

                            // Read the file as data URL (base64)
                            reader.readAsDataURL(selectedFile);
                          } else {
                            // If file type doesn't match, handle the error
                            setCorrectFileType(false);
                          }
                        }
                      }}
                    />
                    {!isCorrectFileType && (
                      <div className="w-100">
                        <p className="text-info">*Only CSV and XLSX file format are allowed.</p>
                      </div>
                    )}
                    <div className="w-100 mt-2">
                      <a className="btn btn-info btn-sm" href="/dummy.csv" download={"dummy"}>
                        *Download dummy CSV. <i className="fa fa-excel ps-2"></i>{" "}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-100 mt-4 text-end">
                  {isloading && (
                    <button role="button" className="btn btn-dark" disabled>
                      <i class="fa fa-circle-o-notch fa-spin me-2"></i> Loading
                    </button>
                  )}

                  {!isloading && isCorrectFileType && csv.length !== 0 && (
                    <button role="submit" className="btn btn-dark">
                      Submit
                    </button>
                  )}

                  {!isloading && !isCorrectFileType && (
                    <button role="button" className="btn btn-dark" disabled>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {/* CSV FORM ENDS */}
    </>
  );
};

export default AddGift;
